import { Component, inject } from '@angular/core';
import { HeaderComponent } from "../shared/header/header.component";
import { AboveTheFoldComponent } from "../above-the-fold/above-the-fold.component";
import { AboutMeComponent } from "../about-me/about-me.component";
import { MySkillsComponent } from "../my-skills/my-skills.component";
import { PortfolioComponent } from "../portfolio/portfolio.component";
import { ContactComponent } from "../contact/contact.component";
import { FooterComponent } from "../shared/footer/footer.component";
import { DataService } from '../data.service';

@Component({
  selector: 'app-main-page',
  standalone: true,
  imports: [HeaderComponent, AboveTheFoldComponent, AboutMeComponent, MySkillsComponent, PortfolioComponent, ContactComponent, FooterComponent],
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.scss'
})
export class MainPageComponent {
  data = inject(DataService);
  title = 'portfolio';
}
