<div data-aos="fade-left" data-aos-duration="1000" class="mainContainer">
    <div class="headlineContainer">
        <div class="line1Container">
            <div class="line1"></div>
        </div>

        <h1>{{text.headline}}</h1>
        <div class="line2"></div>
    </div>
    <p>{{text.text}}</p>
</div>
@for (project of projectListData.projectList; track project.name; let index =$index) {
<app-projects [language]="language" [project]="project"></app-projects>
}