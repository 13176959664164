<div data-aos="fade-right" data-aos-duration="1000" class="headlineContainer">
    <div class="line"></div>
    <h1>{{text.headline}}</h1>
</div>
<div class="contactContainer">
    <div data-aos="fade-right" data-aos-duration="1000" class="textContainer">
        <h2>{{text.textOne}}</h2>
        <p>{{text.textTwo}}</p>
        <p [innerHTML]="text.textThree"></p>
    </div>
    <form data-aos="fade-left" data-aos-duration="1000" (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
        <input #name="ngModel" [(ngModel)]="contactData.name" id="inputName" type="text" placeholder="{{text.nameField}}" name="name" required
            [ngClass]="{ 'empty': !name.valid && contactForm.submitted }"
            [ngClass]="{ 'valid': name.valid  && contactForm.submitted }">
        @if (!name.valid && contactForm.submitted) {
        <span class="isRequired">Your name is required</span>
        }
        <input #mail="ngModel" [(ngModel)]="contactData.email" id="inputMail" type="email" placeholder="{{text.emailField}}" name="mail" required
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            [ngClass]="{ 'empty': !mail.valid && contactForm.submitted }"
            [ngClass]="{ 'valid': mail.valid && contactForm.submitted }">
        @if (!mail.valid && contactForm.submitted) {
        <span class="isRequired">Your email is required</span>
        }
        <textarea #message="ngModel" [(ngModel)]="contactData.message" name="message" minlength="20" id="" cols="30" rows="10" placeholder="{{text.messageField}}"
            required [ngClass]="{ 'empty': !message.valid && contactForm.submitted }"
            [ngClass]="{ 'valid': message.valid && contactForm.submitted }"></textarea>
        @if (!message.valid && contactForm.submitted) {
        <span class="isRequired">Your message is empty</span>
        }
        <label class="container">
            <p>{{text.checkboxText}}<a [routerLink]="['/privacy-policy']" routerLinkActive="router-link-active">{{text.checkboxText2}}</a>{{text.checkboxText3}}</p>
            <input type="checkbox" [(ngModel)]="privacyPolicyChecked" id="checkbox" name="checkbox" required>
            <span class="checkmark"></span>
        </label>
        @if (!privacyPolicyChecked && contactForm.submitted) {
        <span class="isRequired">Please accept the privacy policy</span>
        }
        <div class="sendBTN">
            <button type="submit"
                [ngClass]="{ 'active': contactData.name && mail.valid && contactData.message && privacyPolicyChecked}" [innerHTML]="text.button"></button>
        </div>
    </form>
</div>