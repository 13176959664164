@if (project.order1 == "order1") {
   <div data-aos="fade-right" data-aos-duration="1000" class="mainContainer">
    <div class="imageContainer {{project.order1}}">
        <img src="./../../../assets/images/{{project.img}}" alt="">
    </div>
    <div class="textContainer {{project.order2}}">
        <h2>{{project.name}}</h2>
        <h3>
            @for (item of project.components; track index; let index= $index) {
            {{project.components[index]}}
            }
        </h3>
        @if (language == 'english') {
           <p>{{project.discriptionEnglish}}</p> 
        }
        @else if (language == 'german') {
            <p>{{project.discriptionGerman}}</p> 
        }
        @else {
            <p></p>
        }
        <div class="buttonContainer">
            <a target="_blank" rel="noopener noreferrer" href="{{project.linklivetest}}" class="liveTestBTN">Live
                test</a>
            <a target="_blank" rel="noopener noreferrer" href="{{project.linkgithub}}" class="githubBTN">Github</a>
        </div>
    </div>
</div> 
}
@else {
    <div data-aos="fade-left" data-aos-duration="1000" class="mainContainer">
        <div class="imageContainer {{project.order1}}">
            <img src="./../../../assets/images/{{project.img}}" alt="">
        </div>
        <div class="textContainer {{project.order2}}">
            <h2>{{project.name}}</h2>
            <h3>
                @for (item of project.components; track index; let index= $index) {
                {{project.components[index]}}
                }
            </h3>
            @if (language == 'english') {
               <p>{{project.discriptionEnglish}}</p> 
            }
            @else if (language == 'german') {
                <p>{{project.discriptionGerman}}</p> 
            }
            @else {
                <p></p>
            }
            <div class="buttonContainer">
                <a target="_blank" rel="noopener noreferrer" href="{{project.linklivetest}}" class="liveTestBTN">Live
                    test</a>
                <a target="_blank" rel="noopener noreferrer" href="{{project.linkgithub}}" class="githubBTN">Github</a>
            </div>
        </div>
    </div> 
}
