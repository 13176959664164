<footer>
    <div class="mainFooter">
        <div class="logo">
            <img src="./../../../assets/images/logo_transparent.png" alt="">
            <div class="impressum">
                <a href="impressum">Impressum</a>
            </div>
        </div>
        <div>
            <p> &copy; Marcel Hindrichs 2024</p>
        </div>
        <div class="sozials">
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/TripleXMarcel/">
                <img src="./../../../assets/images/github.png" alt="" srcset="">
            </a>
            <a target="_blank" rel="noopener noreferrer" href="mailto:marcel@marcelhindrichs.com">
                <img src="./../../../assets/images/mail.png" alt="" srcset="">
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://de.linkedin.com/in/marcel-hindrichs-505714192">
                <img src="./../../../assets/images/linkedin.png" alt="" srcset="">
            </a>
        </div>
        <div class="impressumMobile">
            <a [routerLink]="['/impressum']" routerLinkActive="router-link-active">Impressum</a>
        </div>
    </div>

</footer>