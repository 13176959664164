import { NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [FormsModule, NgClass, RouterLink],
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  @Input() text = {
    headline: "",
    textOne: "",
    textTwo: "",
    textThree: "",
    nameField: "",
    emailField: "",
    messageField: "",
    checkboxText: "",
    checkboxText2: "",
    checkboxText3: "",
    button: ""
  }

  http = inject(HttpClient);

  privacyPolicyChecked: boolean = false;

  contactData = {
    name: "",
    email: "",
    message: ""
  }

  mailTest = false;

  post = {
    endPoint: 'https://marcelhindrichs.com/sendMail.php',
    body: (payload: any) => JSON.stringify(payload),
    options: {
      headers: {
        'Content-Type': 'text/plain',
        responseType: 'text',
      },
    },
  };

  onSubmit(ngForm: NgForm) {


    if (ngForm.submitted && ngForm.form.valid && !this.mailTest && this.privacyPolicyChecked) {
      this.http.post(this.post.endPoint, this.post.body(this.contactData))
        .subscribe({
          next: (response) => {

            ngForm.resetForm();
          },
          error: (error) => {
            console.error(error);
          },
          complete: () => console.info('send post complete'),
        });
    } else if (ngForm.submitted && ngForm.form.valid && this.mailTest) {
      console.log(this.contactData);
      ngForm.resetForm();
    }
  }
}
