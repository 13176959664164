<div class="mainContainer">
    <div data-aos="fade-right" data-aos-duration="1000" class="textContainer">
        <h1>{{text.headline}}</h1>
        <p>{{text.textOne}}</p>
        <div class="textIconBox">
            <div class="icon"><img src="./../../assets/images/bulb.png" alt=""></div>
            <p>{{text.textTwo}}</p>
        </div>
        <div class="textIconBox">
            <div class="icon"><img src="./../../assets/images/puzzle.png" alt=""></div>

            <p>{{text.textThree}}</p>
        </div>
    </div>
    <div data-aos="fade-left" data-aos-duration="1000" class="imageContainer">
        <div>
            <img src="./../../assets/images/picture2.png" alt="">
        </div>
        <div class="line"></div>
    </div>
    
</div>
