import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  onVariableChange(newValue: string) {
    this.language = newValue;
  }

  language = 'german';

  skillList = [{
    name: "Angular",
    img: "angular.png"
  },
  {
    name: "TypeScript",
    img: "typescript.png"
  },
  {
    name: "JavaScript",
    img: "javascript.png"
  },
  {
    name: "HTML",
    img: "html.png"
  },
  {
    name: "Firebase",
    img: "firebase.png"
  },
  {
    name: "GIT",
    img: "git.png"
  },
  {
    name: "CSS",
    img: "css.png"
  },
  {
    name: "Api",
    img: "restapi.png"
  },
  {
    name: "Material Design",
    img: "material.png"
  }];

  projectList = [{
    name: "Join",
    img: "leptopJoin.png",
    components: ["JavaScript | ", "HTML | ", "CSS"],
    discriptionEnglish: "Task manager inspired by the Kanban System. Create and organize tasks using drag and drop functions, assign users and categories.",
    discriptionGerman: "Aufgabenmanager inspiriert vom Kanban-System. Erstellen und organisieren Sie Aufgaben mit Drag-and-Drop-Funktionen, weisen Sie Benutzer und Kategorien zu.",
    linkgithub: "https://github.com/TripleXMarcel/Join",
    linklivetest: "http://join.marcelhindrichs.com",
    order1: "order1",
    order2: "order2"
  },
  {
    name: "El Pollo Loco",
    img: "leptopElPoloLoco.png",
    components: ["JavaScript | ", "HTML | ", "CSS"],
    discriptionEnglish: "A simple Jump-and-Run game based on an object-oriented approach. Help Pepe to find coins and salsa bottles to fight against the killer chicken.",
    discriptionGerman: "Einfaches Jump-and-Run-Spiel basierend auf einem objektorientierten Ansatz. Helfen Sie Pepe, Münzen und Salsaflaschen zu finden, um gegen den Killerhahn zu kämpfen.",
    linkgithub: "https://github.com/TripleXMarcel/El-Pollo-Loco",
    linklivetest: "http://elpolloloco.marcelhindrichs.com",
    order1: "order2",
    order2: "order1"
  },
  {
    name: "Pokédex",
    img: "leptopPokedex.png",
    components: ["JavaScript | ", "HTML | ", "CSS | ", "API"],
    discriptionEnglish: "Based on the PokéAPI a simple library that provides and catalogues pokemon information.",
    discriptionGerman: "Basierend auf der PokéAPI eine einfache Bibliothek, die Pokémon-Informationen bereitstellt und katalogisiert.",
    linkgithub: "https://github.com/TripleXMarcel/PokeDex",
    linklivetest: "http://pokedex.marcelhindrichs.com",
    order1: "order1",
    order2: "order2"
  }]

  textEnglish = [{
    header: {
      textAboutMe: "About me",
      textSkills: "Skills",
      textPortfolio: "Portfolio",
      textContact: "Contact"
    },
    aboveTheFold: {
      textIAm: "I am",
      textFrontendDeveloper: "Frontend Developer",
      textButtonLetsTalk: "Let's talk!",
      textScrollDown: "Scroll down"
    },
    aboutMe: {
      headline: "About me",
      textOne: "Hello, I'm a front-end developer from Hückeswagen, Germany. Welcome to my portfolio! My name is Marcel Hindrichs, a passionate front-end developer who has chosen to design user-oriented web applications. In this portfolio, you will find a selection of projects that reflect my current skill set.",
      textTwo: "My passion for IT and problem-solving began early, and I was fascinated by the variety of technical challenges and the opportunities they offered. Furthermore, I continuously strive to expand my knowledge in the field of computer technology and to keep up with the latest trends and developments in the market. I firmly believe that lifelong learning is essential to succeed in the fast-paced IT sector and to keep my skills up-to-date.",
      textThree: "In my previous professional experiences, I have demonstrated how I can put this passion into practice. Currently, I work as an industrial mechanic and specialize in the construction of measuring machines. This work involves a great deal of precise and accurate work. In addition, close cooperation with my colleagues is crucial. These things are important to solve complex problems and achieve high-quality results."
    },
    mySkills: {
      headline: "My skills",
      text: "I have gained experience in building projects with various front end technologies and concepts.",
      button: "Get in touch"
    },
    portfolio: {
      headline: "Portfolio",
      text: "Explore a selection of my work here - Interact with projects to see my skills in action."
    },
    contact: {
      headline: "Contact",
      textOne: "Got a problem to solve?",
      textTwo: "Contact me through this form, I am interested in hearing from you, knowing your ideas and contributing to your projects with my work.",
      textThree: "Need a Frontend developer? <b>Contact me!</b>",
      nameField: "Your name",
      emailField: "Your email",
      messageField: "Your message",
      checkboxText: "I've read the ",
      checkboxText2:"privacy policy ",
      checkboxText3:"and agree to the processing of my data as outlined.",
      button: "Send message :)"
    }
  }]

  textGerman = [{
    header: {
      textAboutMe: "Über mich",
      textSkills: "Fähigkeiten",
      textPortfolio: "Portfolio",
      textContact: "Kontakt"
    },
    aboveTheFold: {
      textIAm: "Ich bin",
      textFrontendDeveloper: "Frontend-Entwickler",
      textButtonLetsTalk: "Lass uns reden!",
      textScrollDown: "nach unten Scrollen"
    },
    aboutMe: {
      headline: "Über mich",
      textOne: "Hallo, ich bin ein Front-End-Entwickler aus Hückeswagen, Deutschland. Willkommen zu meinem Portfolio! Ich bin Marcel Hindrichs, ein leidenschaftlicher Front-End-Entwickler, der sich für die Gestaltung nutzerorientierte Web-Anwendungen entschieden hat. In diesem Portfolio finden Sie eine Auswahl an Projekten, welche meinen Wissensstand widerspiegeln.",
      textTwo: "Meine Leidenschaft für IT und Problemlösung begann früh, und ich war fasziniert von der Vielfalt technischer Herausforderungen und den Möglichkeiten, die sie boten. Darüber hinaus strebe ich kontinuierlich danach, mein Wissen in Computertechnologie zu erweitern und stets auf dem neuesten Stand der Trends und Entwicklungen am Markt zu bleiben. Ich bin fest davon überzeugt, dass lebenslanges Lernen entscheidend ist, um im schnelllebigen IT-Sektor erfolgreich zu sein und meine Fähigkeiten auf dem neuesten Stand zu halten.",
      textThree: "In meinen bisherigen beruflichen Erfahrungen habe ich gezeigt, wie ich diese Leidenschaft in die Praxis umsetzen kann. Derzeit arbeite ich als Industriemechaniker und bin spezialisiert auf den Bau von Messmaschinen. Diese Arbeit hat viel mit präziser und genauer Arbeit zu tun. Außerdem ist engen Zusammenarbeit mit meinen Kollegen entscheidend. Diese Dinge sind wichtig, um komplexe Probleme zu lösen und qualitativ hochwertige Ergebnisse zu erzielen."
    },
    mySkills: {
      headline: "Meine Fähigkeiten",
      text: "Ich habe Erfahrung in der Erstellung von Projekten mit verschiedenen Front-end-Technologien und -Konzepten gesammelt.",
      button: "Kontakt aufnehmen"
    },
    portfolio: {
      headline: "Portfolio",
      text: "Entdecken Sie hier eine Auswahl meiner Arbeiten - Interagieren Sie mit den Projekten, um meine Fähigkeiten in Aktion zu sehen."
    },
    contact: {
      headline: "Kontakt",
      textOne: "Hast du ein Problem zu lösen?",
      textTwo: "Kontaktieren Sie mich über dieses Formular, ich interessiere mich für Ihre Nachrichten, Ihre Ideen und möchte zu Ihren Projekten mit meiner Arbeit beitragen.",
      textThree: "Benötigen Sie einen Frontend-Entwickler? <b>Kontaktieren Sie mich!</b>",
      nameField: "Ihr Name",
      emailField: "Ihre E-Mail",
      messageField: "Ihre Nachricht",
      checkboxText: "Ich habe die ",
      checkboxText2:"Datenschutzerklärung",
      checkboxText3:" gelesen und stimme der Verarbeitung meiner Daten wie beschrieben zu.",
      button: "Nachricht senden :)"
    }
  }]

}

