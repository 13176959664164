import { Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-skills',
  standalone: true,
  imports: [],
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent {
  @Input() skill = {
    name: "Angular",
    img: "angular.png"
  }

  @Output()skillname = new EventEmitter<string>();
}
