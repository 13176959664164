import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [],
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent {
  @Input() project = {
    name: "Join",
    img: "leptopJoin.png",
    components: ["JavaScript", "HTML", "CSS"],
    discriptionEnglish: "Task manager inspired by the Kanban System. Create and organize tasks using drag and drop functions, assign users and categories.",
    discriptionGerman: "",
    linkgithub: "",
    linklivetest: "",
    order1: "order1",
    order2: "order2"
  }

  @Input() language: string | undefined;

  @Output() projectname = new EventEmitter<string>();
}
