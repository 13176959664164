import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-above-the-fold',
  standalone: true,
  imports: [],
  templateUrl: './above-the-fold.component.html',
  styleUrls: ['./above-the-fold.component.scss','./responsive.above-the-fold.component.scss']
})
export class AboveTheFoldComponent {
  @Input() text = {
    textIAm: "I am",
    textFrontendDeveloper: "Frontend Developer",
    textButtonLetsTalk: "Let's talk!",
    textScrollDown: "Scroll down"
  }
}
