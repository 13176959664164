<div class="mainContainer">
    <div class="photo">
        <img src="./../../assets/images/mainPhoto2.png" alt="" srcset="">
    </div>
    <div class="headlineContainer">
        <div class="displayFlex">
            <div class="verticalText">
                <h3>I am</h3>
            </div>
            <div class="headline">
                <h1>Marcel Hindrichs</h1>
                <h3>{{text.textFrontendDeveloper}}</h3>
            </div>
        </div>
        <a href="#contact" class="button">
            <button href="#contact">{{text.textButtonLetsTalk}}</button>
        </a>
    </div>
</div>
<div class="atfFooter">
    <div class="atfFooterContainer">
        <div class="line"></div>
        <div class="sozials">
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/TripleXMarcel/">
                <img src="./../../../assets/images/github.png" alt="" srcset="">
            </a>
            <a target="_blank" rel="noopener noreferrer" href="mailto:marcel@marcelhindrichs.com">
                <img src="./../../../assets/images/mail.png" alt="" srcset="">
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://de.linkedin.com/in/marcel-hindrichs-505714192">
                <img src="./../../../assets/images/linkedin.png" alt="" srcset="">
            </a>
        </div>
        <div class="mail">
            <p>marcel&#64;marcelhindrichs.com</p>
        </div>
    </div>
    <div class="scrollDown">
        <p>{{text.textScrollDown}}</p>
        <img src="./../../../assets/images/arrow-down-line.png" alt="" srcset="">
    </div>
</div>

<div class="vector">
    <img src="./../../../assets/images/vector.png" alt="" srcset="">
</div>