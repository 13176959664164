import { Component, inject, Input } from '@angular/core';
import { ProjectsComponent } from './projects/projects.component';
import { DataService } from '../data.service';

@Component({
  selector: 'app-portfolio',
  standalone: true,
  imports: [ProjectsComponent],
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent {
  projectListData = inject(DataService);
  @Input() language: string | undefined;
  @Input() text = {
    headline:"",
    text:""
  }
}
