<header>
    <div class="header">
        <div class="headerLogo"><a href=""><img src="./../../../assets/images/logo_transparent.png" alt=""></a></div>
        <div class="headerLinks">
            <a href="#aboutMe">{{text.textAboutMe}}</a>
            <a href="#mySkills">{{text.textSkills}}</a>
            <a href="#portfolio">{{text.textPortfolio}}</a>
            @if (language == 'english') {
            <img class="flag" (click)="changeLanguage()" src="./../../../assets/images/flagEnglish.jpg" alt="">
            }
            @else if(language == 'german'){
            <img class="flag" (click)="changeLanguage()" src="./../../../assets/images/flagGerman.jpg" alt="">
            }
            @else{
            <img class="flag" (click)="changeLanguage()" src="./../../../assets/images/flagEnglish.jpg" alt="">
            }
        </div>
        <div class="menu">
            @if (isOpen) {
            <img id="imageOpen" (click)="toggle()" class="show" src="./../../../assets/images/burgermenu.png" alt="">
            }
            @else {
            <img id="imageClose" (click)="toggle()" class="show" src="./../../../assets/images/closefinal.png" alt="">
            }
        </div>
    </div>

</header>
<div [ngClass]="{show: !isOpen}" class="dropDownMainContainer">
    <div class="dropDown">
        <a (click)="toggle()" href="#aboutMe">{{text.textAboutMe}}</a>
        <a (click)="toggle()" href="#mySkills">{{text.textSkills}}</a>
        <a (click)="toggle()" href="#portfolio">{{text.textPortfolio}}</a>
        <a (click)="toggle()" href="#contact">{{text.textContact}}</a>
        @if (language == 'english') {
        <img class="flag" (click)="changeLanguage()" src="./../../../assets/images/flagEnglish.jpg" alt="">
        }
        @else if(language == 'german'){
        <img class="flag" (click)="changeLanguage()" src="./../../../assets/images/flagGerman.jpg" alt="">
        }
        @else{
        <img class="flag" (click)="changeLanguage()" src="./../../../assets/images/flagEnglish.jpg" alt="">
        }
    </div>
</div>