import { Component } from '@angular/core';
import { Routes } from '@angular/router';
import { ImpressumComponent } from './impressum/impressum.component';
import { MainPageComponent } from './main-page/main-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

export const routes: Routes = [
    {path:"", component:MainPageComponent},
    {path:"impressum", component:ImpressumComponent},
    {path:"privacy-policy", component:PrivacyPolicyComponent}
];
