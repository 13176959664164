import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, output } from '@angular/core';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgClass],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isOpen = true;
  @Input() language: string | undefined;
  @Input() text = {
    textAboutMe: "About me",
    textSkills: "Skills",
    textPortfolio: "Portfolio",
    textContact: "Contact"
  }
  @Output() variableChange = new EventEmitter<string>();

  toggle() {
    this.isOpen = !this.isOpen;
  }

  changeLanguage() {
    if (this.language == 'german') {
      const newValue = 'english';
      this.variableChange.emit(newValue);
    }
    else if (this.language == 'english') {
      const newValue = 'german';
      this.variableChange.emit(newValue);
    }
  }
}
