import { Component, inject, Input } from '@angular/core';
import { DataService } from '../data.service';
import { SkillsComponent } from './skills/skills.component';

@Component({
  selector: 'app-my-skills',
  standalone:true,
  imports:[SkillsComponent],
  templateUrl: './my-skills.component.html',
  styleUrls: ['./my-skills.component.scss']
})
export class MySkillsComponent {
skilllistdata = inject(DataService);
@Input() text = {
  headline:"",
  text:"",
  button:""
}
}
