<div class="mainContainer">
    <div data-aos="fade-right" data-aos-duration="1000" class="skillContainer">
        @for(skill of skilllistdata.skillList; track skill.name; let index = $index){
        <app-skills [skill]="skill"></app-skills>
        }
    </div>
    <div data-aos="fade-left" data-aos-duration="1000" class="center">
        <div class="textContainer">
            <div class="headline">
                <h1>{{text.headline}}</h1>
                <div class="line"></div>
            </div>
            <div class="text"><p>{{text.text}}</p></div>
            <a href="#contact" class="getInTouchBTN">
                <button>{{text.button}}</button>
            </a>
        </div>

    </div >
    <a href="#contact" class="getInTouchBTNMobile">
        <button>{{text.button}}</button>
    </a>
</div>