@if (data.language == "english") {
<app-header [language]="data.language" [text]="data.textEnglish[0].header"
        (variableChange)="data.onVariableChange($event)"></app-header>
<div class="mainContainer">
        <h1>Impressum</h1>  
        <p><strong>Marcel Hindrichs</strong></p>
        <p>Jung-Stilling-Str. 41</p>
        <p>42499 Hückeswagen</p>
        <p>E-Mail: druidixi&#64;gmail.com</p>
        <p>Telefon: 015165212145</p>
        <p>This imprint covers all content on this website.</p>
</div>
<app-footer></app-footer>
<!-- <div class="backgroundOne"><img src="./../../assets/images/backgroundOne.png" alt=""></div>
    <div class="backgroundTwo"><img src="./../../assets/images/backgroundTwo.png" alt=""></div>
    <div class="backgroundThree"><img src="./../../assets/images/backgroundThree.png" alt=""></div>
    <div class="backgroundFour"><img src="./../../assets/images/backgroundTwo.png" alt=""></div>
       <div class="backgroundFive"><img src="./../../assets/images/backgroundThree.png" alt=""></div> -->
}@else if (data.language == "german") {
<app-header [language]="data.language" [text]="data.textGerman[0].header"
        (variableChange)="data.onVariableChange($event)"></app-header>
<div class="mainContainer">
        <h1>Impressum</h1>  
        <p><strong>Marcel Hindrichs</strong></p>
        <p>Jung-Stilling-Str. 41</p>
        <p>42499 Hückeswagen</p>
        <p>E-Mail: marcel&#64;marcelhindrichs.com</p>
        <p>Telefon: 015165212145</p>
        <p>Dieses Impressum gilt für alle Inhalte dieser Website.</p>
</div>
<app-footer></app-footer>
<!-- <div class="backgroundOne"><img src="./../../assets/images/backgroundOne.png" alt=""></div>
    <div class="backgroundTwo"><img src="./../../assets/images/backgroundTwo.png" alt=""></div>
    <div class="backgroundThree"><img src="./../../assets/images/backgroundThree.png" alt=""></div>
    <div class="backgroundFour"><img src="./../../assets/images/backgroundTwo.png" alt=""></div>
    <div class="backgroundFive"><img src="./../../assets/images/backgroundThree.png" alt=""></div> -->
}
@else {
<div>Error</div>
}