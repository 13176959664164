@if (data.language == "english") {
<app-header [language]="data.language" [text]="data.textEnglish[0].header"
        (variableChange)="data.onVariableChange($event)"></app-header>
<div class="mainContainer">
        <div class="background">
                <div class="backgroundOne"></div>
                <div class="backgroundTwo"></div>
                <div class="backgroundThree"></div>
                <div class="backgroundFour"></div>
                <div class="backgroundFive"></div>
        </div>
        <app-above-the-fold [text]="data.textEnglish[0].aboveTheFold"></app-above-the-fold>
        <app-about-me [text]="data.textEnglish[0].aboutMe" id="aboutMe"></app-about-me>
        <app-my-skills [text]="data.textEnglish[0].mySkills" id="mySkills"></app-my-skills>
        <app-portfolio [text]="data.textEnglish[0].portfolio" [language]="data.language" id="portfolio"></app-portfolio>
        <app-contact [text]="data.textEnglish[0].contact" id="contact"></app-contact>
        <div class="upBTN">
               <a href="#"><svg width="39" height="39" viewBox="0 0 39 39" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="37" height="37" rx="18.5" stroke="white" stroke-width="2" />
                        <path d="M18 15.828L13.343 20.485C12.9526 20.8755 12.3195 20.8755 11.929 20.485C11.5386 20.0945 11.5386 19.4615 11.929 19.071L18.2929 12.7071C18.6834 12.3166 19.3166 12.3166 19.7071 12.7071L26.071 19.071C26.4615 19.4615 26.4615 20.0945 26.071 20.485C25.6806 20.8755 25.0475 20.8755 24.657 20.485L20 15.828L20 27C20 27.5523 19.5523 28 19 28C18.4477 28 18 27.5523 18 27L18 15.828Z"
                                fill="white" />
                </svg></a> 
        </div>
        <app-footer></app-footer>

</div>
}@else if (data.language == "german") {
<app-header [language]="data.language" [text]="data.textGerman[0].header"
        (variableChange)="data.onVariableChange($event)"></app-header>
<div class="mainContainer">
        <div class="background">
                <div class="backgroundOne"></div>
                <div class="backgroundTwo"></div>
                <div class="backgroundThree"></div>
                <div class="backgroundFour"></div>
                <div class="backgroundFive"></div>
        </div>
        <app-above-the-fold [text]="data.textGerman[0].aboveTheFold"></app-above-the-fold>
        <app-about-me [text]="data.textGerman[0].aboutMe" id="aboutMe"></app-about-me>
        <app-my-skills [text]="data.textGerman[0].mySkills" id="mySkills"></app-my-skills>
        <app-portfolio [text]="data.textGerman[0].portfolio" [language]="data.language" id="portfolio"></app-portfolio>
        <app-contact [text]="data.textGerman[0].contact" id="contact"></app-contact>
        <div class="upBTN">
              <a href="#"><svg width="39" height="39" viewBox="0 0 39 39" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="37" height="37" rx="18.5" stroke="white" stroke-width="2" />
                        <path d="M18 15.828L13.343 20.485C12.9526 20.8755 12.3195 20.8755 11.929 20.485C11.5386 20.0945 11.5386 19.4615 11.929 19.071L18.2929 12.7071C18.6834 12.3166 19.3166 12.3166 19.7071 12.7071L26.071 19.071C26.4615 19.4615 26.4615 20.0945 26.071 20.485C25.6806 20.8755 25.0475 20.8755 24.657 20.485L20 15.828L20 27C20 27.5523 19.5523 28 19 28C18.4477 28 18 27.5523 18 27L18 15.828Z"
                                fill="white" />
                </svg></a>  
        </div>
        <app-footer></app-footer>

</div>
}
@else {
<div>Error</div>
}