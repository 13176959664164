@if (data.language == "english") {
<app-header [language]="data.language" [text]="data.textEnglish[0].header"
        (variableChange)="data.onVariableChange($event)"></app-header>
<div class="mainContainer">
        <h1>Privacy Policy</h1>
        <p>Last updated: August  
                05, 2024</p>

        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights and how the law protects  
                You.</p>
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this Privacy Policy. This  
                Privacy Policy has been created with the help of the Privacy Policy Generator.</p>  


        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
                The following  
                definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
         


        <h3>Definitions</h3>
        <ul>
                <li>Account means a unique account created for You to access our Service or parts of our Service.</li>
                <li>Affiliate means an entity that controls, is controlled by or is under common control with a party,
                        where "control" means  
                        ownership of 50% or more of the shares, equity interest or other securities  
                        entitled to vote for election of directors or other managing authority.</li>
                <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers  
                        to Portfolio.</li>
                <li>Cookies are small files that are placed on Your computer, mobile device or any other device by a
                        website,  
                        containing the details of Your browsing history on that website among its many uses.</li> 
                <li>Country refers to: Nordrhein-Westfalen, Germany</li>

        </ul>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <p>By email: <a href="mailto:druidixi@gmail.com">druidixi&#64;gmail.com</a></p>


</div>
<app-footer></app-footer>
<a href="#" class="upBTN"><svg width="39" height="39" viewBox="0 0 39 39" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="37" height="37" rx="18.5" stroke="white" stroke-width="2" />
                <path d="M18 15.828L13.343 20.485C12.9526 20.8755 12.3195 20.8755 11.929 20.485C11.5386 20.0945 11.5386 19.4615 11.929 19.071L18.2929 12.7071C18.6834 12.3166 19.3166 12.3166 19.7071 12.7071L26.071 19.071C26.4615 19.4615 26.4615 20.0945 26.071 20.485C25.6806 20.8755 25.0475 20.8755 24.657 20.485L20 15.828L20 27C20 27.5523 19.5523 28 19 28C18.4477 28 18 27.5523 18 27L18 15.828Z"
                        fill="white" />
        </svg></a>
}@else if (data.language == "german") {
<app-header [language]="data.language" [text]="data.textGerman[0].header"
        (variableChange)="data.onVariableChange($event)"></app-header>
<div class="mainContainer">
        <h1>Privacy Policy</h1>
        <p>Last updated: August  
                05, 2024</p>

        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights and how the law protects  
                You.</p>
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this Privacy Policy. This  
                Privacy Policy has been created with the help of the Privacy Policy Generator.</p>  


        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
                The following  
                definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
         


        <h3>Definitions</h3>
        <ul>
                <li>Account means a unique account created for You to access our Service or parts of our Service.</li>
                <li>Affiliate means an entity that controls, is controlled by or is under common control with a party,
                        where "control" means 
                        ownership of 50% or more of the shares, equity interest or other securities 
                        entitled to vote for election of directors or other managing authority.</li>
                <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers 
                        to Portfolio.</li>
                <li>Cookies are small files that are placed on Your computer, mobile device or any other device by a
                        website, 
                        containing the details of Your browsing history on that website among its many uses.</li> 
                        <li>Country refers to: Nordrhein-Westfalen, Germany</li>
        </ul>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <p>By email: <a href="mailto:druidixi@gmail.com">druidixi&#64;gmail.com</a></p>

</div>
<app-footer></app-footer>
<a href="#" class="upBTN"><svg width="39" height="39" viewBox="0 0 39 39" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="37" height="37" rx="18.5" stroke="white" stroke-width="2" />
                <path d="M18 15.828L13.343 20.485C12.9526 20.8755 12.3195 20.8755 11.929 20.485C11.5386 20.0945 11.5386 19.4615 11.929 19.071L18.2929 12.7071C18.6834 12.3166 19.3166 12.3166 19.7071 12.7071L26.071 19.071C26.4615 19.4615 26.4615 20.0945 26.071 20.485C25.6806 20.8755 25.0475 20.8755 24.657 20.485L20 15.828L20 27C20 27.5523 19.5523 28 19 28C18.4477 28 18 27.5523 18 27L18 15.828Z"
                        fill="white" />
        </svg></a>
}
@else {
<div>Error</div>
}